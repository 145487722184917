import "./App.css"
import BillSplitter from "./components/Amounts"
import "./components/css/index.css"


const App = () => {
  return (
    <>
      <BillSplitter />
      </>
  )
}

export default App
